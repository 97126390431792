import React from 'react';
import style from './index.module.scss'

import logo from '../../assets/logo.svg'

class SelectionArea extends React.Component {
  render() {
    return (
      <div className={style.SelectionArea}
      	   onClick={()=>{ window.location = this.props.url }}>
        {this.props.text}
      </div>
    );
  }
}

export default SelectionArea;