import React from 'react';
import style from './index.module.scss'

class Surfer extends React.Component {
  render() {
    return (
      <div>
        Surfer
      </div>
    );
  }
}

export default Surfer;