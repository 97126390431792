import React from 'react';
import style from './index.module.scss'

import logo from '../../assets/logo.svg'

import SelectionArea from '../../Component/SelectionArea';

class Home extends React.Component {
  render() {
    return (
      <div className={style.HomePage}>
        <div className={style.Logo}>
          <img src={logo} />
        </div>
        <div className={style.SubTitleText}>
          <span className={style.SubTitle}> Hi 👋 </span>
        </div>
        <div className={style.Text}>
          My name is <span className={style.Emphasis}>Abhi</span>
        </div>
        <div className={style.Text}>
          For the past <span className={style.Emphasis}>two</span> years, I’ve lived in <span className={style.Emphasis}>4</span> different cities and I've met some <span className={style.Emphasis}>incredible</span> people
        </div>
        <div className={style.SubTitleText}>
          <span className={style.SubTitle}> I've learned that </span>
        </div>
        <div className={style.Text}>
          Living with someone is the best way to <span className={style.Emphasis}>build</span> a relationship
        </div>
        <div className={style.Text}>
          There are incredible people working on <span className={style.Emphasis}>meaningful</span> and <span className={style.Emphasis}>inspiring</span> things
        </div>
        <div className={style.Text}>
          Everyone should have a chance to travel, exchange ideas and create <span className={style.Emphasis}>meaningful</span> relationships
        </div>
        <div className={style.TextSmall}>
          (📚 <a href={"https://abhi.nyc/"}>Read More</a> Here ✈️)
        </div>
        <div className={style.SubTitleText}>
          <span className={style.SubTitle}> Why now? </span>
        </div>
        <div className={style.Text}>
          Now more than ever, students are taking gap years and looking for <span className={style.Emphasis}>meaning</span> outside of traditional education
        </div>
        <div className={style.Text}>
        </div>
        <div className={style.Text}>
          Let's start a <span className={style.Emphasis}>revolution</span>
        </div>
        <div className={style.Text}>
          Let's create <span className={style.Emphasis}>stories</span>
        </div>
        <div className={style.Text}>
          Let's make <span className={style.Emphasis}>KewlCouch</span> together
        </div>
        <div className={style.Text}>
        </div>
        <div className={style.Text}>
        </div>
        <div className={style.HomePageSelection}>
          <SelectionArea text={"I have a couch"}
                         url={"https://docs.google.com/forms/d/e/1FAIpQLSfaVE-ce5t63Nvlew4oJccHGqS8lFf90txmwAcUwt8zZmUK8A/viewform?usp=sf_link"}/>
          <SelectionArea text={"I need a couch"}
                         url={"https://docs.google.com/forms/d/e/1FAIpQLSevIPSrnY-F47bOZtShEzogwrjVARQg8_ymhFGDurNRUcTkbg/viewform?usp=sf_link"}/>
        </div>
        <div className={style.Text}>
        </div>
        <div className={style.Text}>
        </div>
        <div className={style.FooterContainer}>
          <div className={style.FooterContainerText}>
            Made with ❤️ SF
          </div>
          <div className={style.FooterContainerText}>
            KewlCouch © 2020
          </div>
          <div className={style.Text}>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;