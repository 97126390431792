import React from 'react';
import style from './index.module.scss'

class NotFound extends React.Component {
  render() {
    return (
      <div>
        Not Found
      </div>
    );
  }
}

export default NotFound;