import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from 'react-router-dom';
import style from './App.module.scss';

import Home from './Pages/Home';
import Host from './Pages/Host';
import NotFound from './Pages/NotFound';
import Surfer from './Pages/Surfer';

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className={style.App}>
          <Switch>
            <Route exact
                   path='/'
                   render={(props) => <Home/>}/>
            <Route path='/404'
                   render={(props) => <NotFound/>} />
            <Redirect from='*' to='/404' />
          </Switch>
        </div>
      </Router>
    );
  }
}


/*
            <Route exact
                   path='/host'
                   render={(props) => <Host/>}/>
            <Route exact
                   path='/surfer'
                   render={(props) => <Surfer/>}/>
*/

export default App;