import React from 'react';
import style from './index.module.scss'

class Host extends React.Component {
  render() {
    return (
      <div>
        Host
      </div>
    );
  }
}

export default Host;